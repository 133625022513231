import { Box, Container } from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import { Post } from '../../types';
import Fields, { resolveFields } from '../Fields';
import ArticleContainer from '../ArticleContainer';
import ArticleHero from '../ArticleHero';

const SocialShare = dynamic(() => import('../SocialShare'), {
  ssr: false,
});

export const splitFields = (
  fields: Post['fields'],
  basicFieldKeys = [
    'core/',
    'video',
    'accordion',
    'membership-fee',
    'board-members',
    'buttons',
  ],
): { basicFields: Post['fields']; sectionFields: Post['fields'] } =>
  fields.reduce(
    (acc, { __name, ...field }) => {
      const key =
        __name && basicFieldKeys.some((k) => __name.includes(k))
          ? 'basicFields'
          : 'sectionFields';
      return {
        ...acc,
        [key]: [...acc[key], { ...field, __name }],
      };
    },
    {
      basicFields: [],
      sectionFields: [],
    },
  );

const Page = ({
  fields,
  post_title: title,
  post_excerpt: leadText,
  featured_image,
  shareable,
}: Post) => {
  const [hero, contentFields] = resolveFields(fields);
  const { basicFields, sectionFields } = splitFields(contentFields);

  const heroProps = hero
    ? {
        title: hero.heading?.title || title,
        leadText: hero.text || leadText,
        image: hero.image || featured_image,
      }
    : { title, leadText, image: featured_image };

  return (
    <ArticleContainer>
      {/* Box here is to avoid grid-gap between hero and content, which comes from ArticleContainer */}
      <Box>
        <ArticleHero {...heroProps} noMeta />
        <Container maxW="container.sm" variant="noSpacing">
          <Fields fields={basicFields} fieldsType="basic" />
          {shareable ? <SocialShare title={title} /> : null}
        </Container>
      </Box>
      <Fields fields={sectionFields} />
    </ArticleContainer>
  );
};

export default Page;

import {
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  ChakraProps,
  Text,
} from '@chakra-ui/react';
import { useAppdata, useTranslation } from '../lib/hooks.context';
import { getTextChildren } from '../lib/utils';
import Link from './Link';

const Breadcrumb = ({ color, ...props }: ChakraProps) => {
  const { breadcrumb, subsite } = useAppdata();
  const t = useTranslation();

  const items = [
    {
      url: subsite ? `/${subsite}` : '/',
      title: t('frontpage'),
    },
    ...(breadcrumb || []),
  ].filter(({ title }) => !!title);

  return (
    <ChakraBreadcrumb
      {...props}
      color={color}
      sx={{
        'a + span': { color: 'separatorColor' },
        '[aria-current="page"]': { color: color || 'secondaryColor' },
      }}
      maxWidth="100%"
      overflow="hidden"
      py="4"
    >
      {items?.map(({ url, title }, i) => (
        <BreadcrumbItem isCurrentPage={i === items.length - 1} key={url}>
          <Link as={BreadcrumbLink} href={url} maxWidth="290px">
            <Text {...getTextChildren({ children: title })} isTruncated />
          </Link>
        </BreadcrumbItem>
      ))}
    </ChakraBreadcrumb>
  );
};

export default Breadcrumb;

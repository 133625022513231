import { Box, Container, Grid, Text } from '@chakra-ui/react';
import Image from './Image';
import ArticleInfo from './ArticleInfo';
import Breadcrumb from './Breadcrumb';
import Heading from './Heading';
import { MhyImage } from '../types';

interface ArticleHeroProps {
  title: string;
  image?: MhyImage;
  date?: string;
  noMeta?: boolean;
  leadText?: string;
}

const ArticleHero = ({
  title,
  image,
  date,
  leadText,
  noMeta,
}: ArticleHeroProps) => (
  <>
    <Breadcrumb />
    <Box pt={[5, null, 10]}>
      <Container
        maxW="container.sm"
        as={Grid}
        gridGap={[4, 4, 8, 8]}
        gridTemplateColumns="100%"
        variant="main"
      >
        <Heading as="h1" variant="h1">
          {title}
        </Heading>
        {noMeta ? null : <ArticleInfo date={date} />}
        {leadText && <Text textStyle="xl">{leadText}</Text>}
      </Container>
      {image && (
        <Container maxW="container.lg" variant="main">
          <Image
            src={image?.src}
            alt={image?.alt || ''}
            width="1080"
            height="674"
            layout="responsive"
            priority
          />
        </Container>
      )}
    </Box>
  </>
);

export default ArticleHero;
